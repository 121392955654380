import { Component, OnInit } from '@angular/core';
import * as a from '../../../../assets/store_details.json';
import {Router} from "@angular/router";
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  storeDetails: any;

  constructor(private router: Router) {
    this.storeDetails = a.stores;
  }

  ngOnInit(): void {
  }

  storeDetailRedirect(id) {
    console.log('id', id);
    if (id <= 6) {
      this.router.navigate(['/store-details', id])
    }
  }
}
