<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-12 footer_logo text-center">
        <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/logo.svg">
      </div>
    </div>
    <div class="social-links text-center pt-3 pt-md-0">
      <a href="https://instagram.com/twiscjp?r=nametag "><img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/insta.png"/></a>
      <a href="https://twitter.com/twiscjp?s=21"><img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/twitter.png"/></a>
    </div>
  </div>

  <div class="footer-top">
    <div class="container">
      <div class="row">


        <div class="col-lg-4 col-md-6 col-xs-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="/details">Twiscの特徴</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/company">会社概要（運営会社）</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/contact-us">特定商取引に関する表記</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/privacy-policy">プライバシーポリシー</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/terms-services">利用規約</a></li> <!-- Date is Right -->
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-6 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="/contact-us?scroll_to=1">配送方法・配送料</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/contact-us?scroll_to=2">お支払いについて</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/faq">よくあるご質問</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/contact-us">お問い合わせ</a></li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-6 footer-links">
          <h4>Social Links</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="https://twitter.com/twiscjp?s=21"><span class="copyright">公式Twitter</span></a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="https://www.instagram.com/twiscjp/?r=nametag"><span class="copyright">公式instagram</span></a></li>
          </ul>
        </div>

      </div>
    </div>
  </div>

  <div class="container py-4" style="border-top: 1px solid #ddd;">

    <div class="text-center">
      <div class="copyright">
        &copy; Copyright <strong><span>Twisc</span></strong>. All Rights Reserved
      </div>
    </div>
  </div>
</footer>
<!-- End Footer -->

<a class="buy_now" (click)="openWindowCustomClass(content)">今すぐ始める</a>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Twisc先行仮会員登録</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="emailDetails">
      <div class="form-group">
        <label for="exampleInputEmail1">お名前</label>
        <input type="name" class="form-control" id="exampleInputEmail1" placeholder="お名前" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
          <div *ngIf="f.name.errors.required">お名前は必須です。</div>
        </div>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">メールアドレス</label>
        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="メールアドレス" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">メールアドレスは必須です。</div>
          <div *ngIf="f.email.errors.email">有効なメールアドレスを入力してください。</div>
      </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="sendEmail()">登録する</button>
    <!-- <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button> -->
  </div>
</ng-template>
