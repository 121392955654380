<!-- End Header -->
 <!-- ======= Header ======= -->
 <header id="header" class="fixed-top ">
  <div class="container d-flex align-items-center justify-content-between">

    <h1 class="logo"><a href="index.html"><img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/logo.svg" class="mainlogo"/>
      <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/logo_white.png" class="shortlogo"/>
    </a></h1>

    <nav class="nav-menu d-none d-lg-block">
      <ul>
        <li class="nav-item">
            <a class="nav-link" href="#portfolio">ストア一覧</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/faq">FAQ</a>
        </li>
        <li class="nav-item">
          <a class="nav-link more-button" (click)="openWindowCustomClass(content)">今すぐ始める</a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link" href="#">ログイン</a>
            <span class="iconify" data-icon="bx:bx-exit" data-inline="false"></span>
        </li>
        <li class="nav-item p-0">
            <a class="nav-link cart" href="#"> <span>0</span></a>
        </li> -->
      </ul>
    </nav><!-- .nav-menu -->

  </div>
</header><!-- End Header -->


<!-- <ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <h1>Hello</h1>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<hr>

<pre>{{ closeResult }}</pre> -->


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Twisc先行仮会員登録</h4>
    <!-- <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/logo_white.png" class="shortlogo"/> -->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="emailDetails">
      <div class="form-group">
        <label for="exampleInputEmail1">お名前</label>
        <input type="name" class="form-control" id="exampleInputEmail1" placeholder="お名前" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
          <div *ngIf="f.name.errors.required">お名前は必須です。</div>
        </div>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">メールアドレス</label>
        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="メールアドレス" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">メールアドレスは必須です。</div>
          <div *ngIf="f.email.errors.email">有効なメールアドレスを入力してください。</div>
      </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="sendEmail()">登録する</button>
    <!-- <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button> -->
  </div>
</ng-template>
