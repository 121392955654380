import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {  FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '../global.service';
@Component({
  selector: 'app-application-header',
  templateUrl: './application-header.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./application-header.component.css']
})
export class ApplicationHeaderComponent implements OnInit {
  emailDetails: FormGroup;
  closeResult: string;
  submitted = false;
  modelOpen = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private global: GlobalService
  ) { }

  ngOnInit(): void {
    this.emailDetails = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.emailDetails.controls; }

  sendEmail() {
    this.submitted = true;
    console.log(this.emailDetails.valid);
    if (this.emailDetails.valid) {
      this.global.sendEmail(this.emailDetails.value.name, this.emailDetails.value.email).subscribe((response) => {
        this.submitted = false;
        this.modalService.dismissAll();
      });
    }
  }

  // open(content) {
  //   this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  openBackDropCustomClass(content) {
    this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
  }

  openWindowCustomClass(content) {
    if (!this.modelOpen) {
      this.emailDetails.controls.name.setValue('');
      this.emailDetails.controls.email.setValue('');
      this.modelOpen = !this.modelOpen;
      this.modalService.open(content, { windowClass: 'dark-modal' }).result.then((result) => {
        this.modelOpen = !this.modelOpen;
        console.log('Closed');
      }, (reason) => {
        this.modelOpen = !this.modelOpen;
        console.log('Dismissed');
      });
    }
  }

}
