import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {  FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '../global.service';
@Component({
  selector: 'app-application-footer',
  templateUrl: './application-footer.component.html',
  styleUrls: ['./application-footer.component.css']
})
export class ApplicationFooterComponent implements OnInit {
  emailDetails: FormGroup;
  closeResult: string;
  submitted = false;
  modelOpen = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private global: GlobalService,
    private formBuilder: FormBuilder
    ) { }

  ngOnInit(): void {
    this.emailDetails = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.emailDetails.controls; }

  sendEmail() {
    this.submitted = true;
    console.log(this.emailDetails.valid);
    if (this.emailDetails.valid) {
      this.global.sendEmail(this.emailDetails.value.name, this.emailDetails.value.email).subscribe((response) => {
        this.submitted = false;
        this.modalService.dismissAll();
      });
    }
  }

  openWindowCustomClass(content) {
    if (!this.modelOpen) {
      this.emailDetails.controls.name.setValue('');
      this.emailDetails.controls.email.setValue('');
      this.modelOpen = !this.modelOpen;
      this.modalService.open(content, { windowClass: 'dark-modal' }).result.then((result) => {
        this.modelOpen = !this.modelOpen;
        console.log('Closed');
      }, (reason) => {
        this.modelOpen = !this.modelOpen;
        console.log('Dismissed');
      });
    }
  }

}
