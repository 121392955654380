import { Component, OnInit, AfterViewInit } from '@angular/core';
import { loadScript } from '../loadscript';
@Component({
  selector: 'app-application-body',
  templateUrl: './application-body.component.html',
  styleUrls: ['./application-body.component.css']
})
export class ApplicationBodyComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
    // loadScript();
  }

  ngAfterViewInit() {
    loadScript();
  }
}
