<!-- ======= Portfolio Section ======= -->
<section id="portfolio" class="portfolio">
  <div class="container">

    <div class="section-title">
      <h3><span>ストア</span></h3>
    </div>

    <div class="row portfolio-container">
      <div class="col-lg-4 col-md-6 portfolio-item filter-app" *ngFor="let store of storeDetails; let i = index" (click)="storeDetailRedirect(store.key)">
       <div class="wrapper_category">
          <img src='{{ store.value.main_image }}' class="img-fluid" alt="">
        </div>
          <div class="portfolio-info">
            <h4>{{ store.value.title }}</h4>
            <p>{{ store.value.title_1 }}</p>
          </div>
      </div>
    </div>

  </div>

    <div class="row product slider">

      <div *ngFor="let store of storeDetails; let i = index" (click)="storeDetailRedirect(store.key)">
        <div class="wrapper_category">
          <img src='{{ store.value.main_image }}' class="img-fluid" alt="">
        </div>
        <div class="store_wrap">
          <h4>{{ store.value.title}}</h4>
          <p>{{ store.value.description}}</p>
        </div>
      </div>
    </div>

</section>
<!-- End Portfolio Section -->
