import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { ApplicationBodyRoutingModule } from './application-body/application-body-routing.module';

const routes: Routes = [
  // {path: '' , redirectTo: 'AppComponent', pathMatch: 'full'},
  { path: 'application-body', loadChildren: () => import('./application-body/application-body.module').then(m => m.ApplicationBodyModule) },
  // { path: 'store-listing', loadChildren: () => import('./application-pages/store-listing/store-listing.module').then(m => m.StoreListingModule) },
  { path: 'store-details/:id', loadChildren: () => import('./application-pages/store-details/store-details.module').then(m => m.StoreDetailsModule) },
  { path: 'site-info', loadChildren: () => import('./application-body/application-body-sections/site-info/site-info.module').then(m => m.SiteInfoModule) },
  { path: 'details', loadChildren: () => import('./application-footer/footer-pages/twisc-details/twisc-details.module').then(m => m.TwiscDetailsModule) },
  { path: 'faq', loadChildren: () => import('./application-footer/footer-pages/faq/faq.module').then(m => m.FaqModule) },
  { path: 'company', loadChildren: () => import('./application-footer/footer-pages/comapny/comapny.module').then(m => m.ComapnyModule) },
  { path: 'terms-services', loadChildren: () => import('./application-footer/footer-pages/terms-service/terms-service.module').then(m => m.TermsServiceModule) },
  { path: 'privacy-policy', loadChildren: () => import('./application-footer/footer-pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'contact-us', loadChildren: () => import('./application-footer/footer-pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  // { path: 'thank-you', loadChildren: () => import('./application-pages/thank-you/thank-you.module').then(m => m.ThankYouModule) },
  // { path: 'profile', loadChildren: () => import('./application-pages/profile/profile.module').then(m => m.ProfileModule) },
  // { path: 'subsc-details', loadChildren: () => import('./application-pages/subsc-details/subsc-details.module').then(m => m.SubscDetailsModule) },
  // { path: 'subsc-manage', loadChildren: () => import('./application-pages/subsc-manage/subsc-manage.module').then(m => m.SubscManageModule) },
  // { path: 'cart', loadChildren: () => import('./application-pages/cart/cart.module').then(m => m.CartModule) },
  // { path: 'home', loadChildren: () => import('./application-pages/home/home.module').then(m => m.HomeModule) },
  // { path: 'order', loadChildren: () => import('./application-pages/order/order.module').then(m => m.OrderModule) },
  // { path: 'customer-info', loadChildren: () => import('./application-pages/customer-info/customer-info.module').then(m => m.CustomerInfoModule) },
  // { path: 'del-sched', loadChildren: () => import('./application-pages/del-sched/del-sched.module').then(m => m.DelSchedModule) },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ApplicationBodyRoutingModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      useHash: false
    }),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
