<!-- ======= Services Section ======= -->
<section id="services" class="services">
  <div class="container">
    <div class="section-title">
      <h3 class="m-o"><span>What’s Twisc</span></h3>
      <p class="sub-text">ツイスクは複数ストア合同の「サブスクリプションボックス」サービスです。<br>
        全国から厳選した商品を毎月１回、定額でお届けします。<br>
        どんな商品が届くかは届くまでのおたのしみ！ <br>
        毎月新しいワクワクをお届けします！
      </p>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="about_box">
        <div class="img_wrap">
          <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/twisc-img/Fotoram.io (3).jpg">
        </div>
        <div class="content_about">
          <div>
            <h4 class="title"><a href="">毎月１回定額でお届け</a></h4>
            <p class="description">ツイスクは、全国の注目ストアから厳選した商品を毎月１回定額でお届けする新しいサブスクリプションBOXサービスです。</p>
          </div>
        </div>
      </div>

      <div class="about_box right_side">
        <div class="img_wrap">
          <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/twisc-img/Fotoram.io (6).jpg">
        </div>
        <div class="content_about">
          <div>
            <h4 class="title"><a href="">毎月10日〜25日にお届け</a></h4>
          <p class="description">月末日の23時59分までのご注文で、翌月より10日〜25日の間のご指定日に毎月商品をお届けいたします。<br>お届け日は月末日の23時59分まででしたらご変更も可能です。<br>お支払いは1ヶ月分の代金を毎月お支払いただきます。</p>
          </div>
        </div>
      </div>

      <div class="about_box">
        <div class="img_wrap">
          <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/twisc-img/Fotoram.io (8).jpg">
        </div>
        <div class="content_about">
          <div>
            <h4 class="title"><a href="">いつでも利用停止可能</a></h4>
          <p class="description">Twisc（ツイスク）はいつでも利用停止可能です。<br>1回のご利用後にも利用停止可能ですので、安心してお気軽にご利用いただけます。<br>もちろんスキップも可能です。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Services Section -->
