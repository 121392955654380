<!-- ======= Hero Section ======= -->
<section id="hero">
  <div class="hero-container">
    <div class="overlay_div">
      <h1>
        新しい出会いをお届け
      </h1>
      <!-- <h1>We're Creative Agency</h1> -->
      <h2>ツイスクは、全国から厳選した商品を<br>毎月１回、定額でお届けします。</h2>
      <!-- <a href="#about" class="btn-get-started scrollto">Get Started</a> -->
  </div>
  </div>
</section>
<!-- End Hero -->

<!-- Start #main -->
<main id="main">
  <!-- <app-site-info></app-site-info> -->

  <app-site-services></app-site-services>

  <!-- ======= Portfolio Section ======= -->
  <app-categories></app-categories>

  <app-slider></app-slider>

  <app-twisc-moshikomi></app-twisc-moshikomi>

  <!-- ======= Priceing Section ======= -->
  <app-pricing></app-pricing>

  <section>
    <div class="container">
          <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h3><span>お知らせ</span></h3>
            </div>
            <form action="" id="wizardews">
              <h4></h4>
              <section>
                <div class="News__content -newItem app-tab__content -activeTab">
                    <ul class="News__newsList">
                        <li class="News__newsItem">
                            <a [routerLink]="['/store-details', 1]" class="News__news Parts-box03">
                                <div class="Parts-box03__labelArea">
                                    <div class="Parts-box03__date">2021.04.01</div>
                                    <span class="Parts-box03__label">Newストア</span>
                                </div>
                                <p class="Parts-box03__text">オープンのお知らせ【bistro sanglier 久助】</p>
                            </a>
                        </li>

                        <li class="News__newsItem">
                            <a [routerLink]="['/store-details', 2]" class="News__news Parts-box03">
                                <div class="Parts-box03__labelArea">
                                    <div class="Parts-box03__date">2021.04.01</div>
                                    <span class="Parts-box03__label">Newストア</span>
                                </div>
                                <p class="Parts-box03__text">オープンのお知らせ【おちゃいち 山陽堂】</p>
                            </a>
                        </li>

                        <!-- <li class="News__newsItem">
                            <a class="News__news Parts-box03">
                                <div class="Parts-box03__labelArea">
                                    <div class="Parts-box03__date">2021.04.01</div>
                                    <span class="Parts-box03__label">Newストア</span>
                                </div>
                                <p class="Parts-box03__text">オープンのお知らせ【月星ソース研究所】</p>
                            </a>
                        </li> -->

                        <li class="News__newsItem">
                            <a [routerLink]="['/store-details', 3]" class="News__news Parts-box03">
                                <div class="Parts-box03__labelArea">
                                    <div class="Parts-box03__date">2021.04.01</div>
                                    <span class="Parts-box03__label">Newストア</span>
                                </div>
                                <p class="Parts-box03__text">オープンのお知らせ【中華レストラン さんぷく】</p>
                            </a>
                        </li>

                        <!-- <li class="News__newsItem">
                            <a class="News__news Parts-box03">
                                <div class="Parts-box03__labelArea">
                                    <div class="Parts-box03__date">2021.04.01</div>
                                    <span class="Parts-box03__label">Newストア</span>
                                </div>
                                <p class="Parts-box03__text">オープンのお知らせ【和バウムクーヘン工房 つか本】</p>
                            </a>
                        </li> -->

                        <li class="News__newsItem">
                          <a [routerLink]="['/store-details', 4]" class="News__news Parts-box03">
                              <div class="Parts-box03__labelArea">
                                  <div class="Parts-box03__date">2021.04.01</div>
                                  <span class="Parts-box03__label">Newストア</span>
                              </div>
                              <p class="Parts-box03__text">オープンのお知らせ【生パスタ専門店 ジェノバ】</p>
                          </a>
                      </li>
                      </ul>
                </div>
              </section>

              <h4></h4>
              <section>
                <div class="News__content -newItem app-tab__content -activeTab">
                    <ul class="News__newsList">
                        <li class="News__newsItem">
                            <a class="News__news Parts-box03">
                                <div class="Parts-box03__labelArea">
                                    <div class="Parts-box03__date">2021.04.01</div>
                                    <span class="Parts-box03__label">サービス</span>
                                </div>
                                <p class="Parts-box03__text">プランが公開されました。</p>
                            </a>
                        </li>

                        <li class="News__newsItem">
                            <a class="News__news Parts-box03">
                                <div class="Parts-box03__labelArea">
                                    <div class="Parts-box03__date">2021.04.01</div>
                                    <span class="Parts-box03__label">サービス</span>
                                </div>
                                <p class="Parts-box03__text">ストアが追加されました。</p>
                            </a>
                        </li>

                        <li class="News__newsItem">
                            <a class="News__news Parts-box03">
                                <div class="Parts-box03__labelArea">
                                    <div class="Parts-box03__date">2021.04.01</div>
                                    <span class="Parts-box03__label">サービス</span>
                                </div>
                                <p class="Parts-box03__text">先行登録が開始されました。</p>
                            </a>
                        </li>
                      </ul>
                </div>
              </section>
            </form>
          </div>
          </div>
      </div>
  </section>

</main>
<!-- End #main -->
