import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    private http: HttpClient
  ) { }

  sendEmail(name, email_id) {
    console.log('email', email_id);
    const data = {
      'name': name,
      'email_id': email_id
    }
    return this.http.post('https://nagw2noowj.execute-api.ap-northeast-1.amazonaws.com/dev/send_email', data);
  }

}
