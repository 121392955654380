import { Component, OnInit } from '@angular/core';
import * as a from '../../../../assets/slider.json';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  sliderImages: Object;

  constructor() { }

  ngOnInit(): void {
    this.sliderImages = this.arrayShuffle(a.slider_images);
    // console.log('this.sliderImages', this.sliderImages);
  }

  arrayShuffle(array) {
    var m = array.length, t, i;

    // While there remain elements to shuffle
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }

}
