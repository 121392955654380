<section class="TopMain__howTo HowTo">
  <div class="HowTo__imageArea app-scrollObserver__elem" data-animation-type="curtainOpen" style="background-image: url(https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/how_to_bg.jpg);opacity: 0.7;">
    <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/how_to_bg.jpg" alt="" class="HowTo__image">
  </div>
  <div class="HowTo__panel">

      <div class="HowTo__titleArea Parts-titleArea">
        <div class="Parts-titleArea__title text-center">
          <h3><span class="how_to">How to Twisc</span></h3>
        </div>
        <p class="Parts-titleArea__text">ウェブサイトから<br class="notSp">カンタンお申し込み</p>
      </div>
      <div class="HowTo__main">
          <div class="HowTo__howTo app-scrollObserver__elem" data-animation-type="stagger">
              <ul class="HowTo__howToList">
                  <li class="HowTo__howToItem">
                      <div class="HowTo__howToBox Parts-box02 app-scrollObserver__child" data-entry-name="slideIn" style="opacity: 1; transform: translate(0px, 0px);">
                          <div class="Parts-box02__titleArea">
                              <span class="Parts-box02__icon">
                                  <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/howTo-icon-01.svg" alt="">
                              </span>

                              <div class="Parts-box02__title">
                                  <span class="Parts-box02__numIcon">
                                      <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/howTo_numIcon-01.png" alt="">
                                  </span>

                                  <h4 class="Parts-box02__titleText">ウェブサイトからお申し込み</h4>
                              </div>
                          </div>
                          <div class="Parts-box02__main">
                              <p class="Parts-box02__text">ツイスクはシンプルな２プラン！<br>お好きなプランをお選びください。</p>
                          </div>
                      </div>
                  </li>
                  <li class="HowTo__howToItem">
                      <div class="HowTo__howToBox Parts-box02 app-scrollObserver__child" data-entry-name="slideIn" style="opacity: 1; transform: translate(0px, 0px);">
                          <div class="Parts-box02__titleArea">
                              <span class="Parts-box02__icon">
                                  <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/howTo-icon-02.svg" alt="">
                              </span>

                              <div class="Parts-box02__title">
                                  <span class="Parts-box02__numIcon">
                                      <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/howTo_numIcon-02.png" alt="">
                                  </span>

                                  <h4 class="Parts-box02__titleText">全国のストアから直接お届け</h4>
                              </div>
                          </div>
                          <div class="Parts-box02__main">
                              <p class="Parts-box02__text">全国の注目ストアから厳選した商品を毎月１回定額でお届けします。</p>
                          </div>
                      </div>
                  </li>
                  <li class="HowTo__howToItem">
                      <div class="HowTo__howToBox Parts-box02 app-scrollObserver__child" data-entry-name="slideIn" style="opacity: 1; transform: translate(0px, 0px);">
                          <div class="Parts-box02__titleArea">
                              <span class="Parts-box02__icon">
                                  <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/howTo-icon-03.svg" alt="">
                              </span>

                              <div class="Parts-box02__title">
                                  <span class="Parts-box02__numIcon">
                                      <img src="https://twisc-static-files.s3-ap-northeast-1.amazonaws.com/images/howTo_numIcon-03.png" alt="">
                                  </span>

                                  <h4 class="Parts-box02__titleText">お受け取り</h4>
                              </div>
                          </div>
                          <div class="Parts-box02__main">
                              <p class="Parts-box02__text">普段のお届けものと同じく受け取るだけ。<br>毎月10〜25日の間のご指定日時にお届けします。</p>
                          </div>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</section>
