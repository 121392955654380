<!-- ======= Pricing Section Start======= -->
<section id="cta" class="cta pricing">
  <div class="container">
    <div class="demo">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <form action="" id="wizard">
              <h4></h4>
              <section>
                <div class="p-index-subscription" style="opacity: 1; transform: translate(0px, 0px);">
                  <div class="p-index-subscription-detail">
                    <div class="p-index-subscription__desc left-div" data-v-58e92e3c="">
                      <h3 class="p-index-subscription__desc-name" data-v-58e92e3c=""><b>お試しプラン</b></h3>
                      <div class="p-index-subscription__desc-price-container">
                        <p class="p-index-subscription__desc-price">¥3,000<span>(税込 ￥3,240)</span></p>
                        <p class="p-index-subscription__desc-info">送料込</p>
                      </div>
                      <!-- <a href="#" target="_blank" class="p-index-subscription__desc-button c-button">カートに入れる</a> -->
                      <button href="#" class="p-index-subscription__desc-button c-button">カートに入れる</button>
                      <p class="p-index-subscription__desc-note">まずは試してみたい、そんな方向けにツイスクをお気軽にお試しいただけるプランをご用意いたしました。<br>
                        全国の注目ストアから厳選した商品をお届けいたします。</p>
                      <br>
                      <p class="p-index-subscription__desc-note">※10〜25日の間のご指定日に配送</p>
                      <p class="p-index-subscription__desc-note">※送料込</p>

                      <!-- <p class="p-index-subscription__desc-subnote">Twisc ではフードロスを解消すると共にできるだけ作りたてのおやつをお届けするために、2回目以降につきましては、発送日の前週の月曜日00:00に事前決済させていただいております。ご理解いただけますと幸いです。</p> -->
                    </div>
                  </div>
                </div>

                <div class="p-index-subscription" style="opacity: 1; transform: translate(0px, 0px);">
                  <div class="p-index-subscription-detail">
                    <div class="p-index-subscription__desc right-div" data-v-58e92e3c="">
                      <h3 class="p-index-subscription__desc-name" data-v-58e92e3c=""><b>スタンダードプラン</b></h3>
                      <div class="p-index-subscription__desc-price-container">
                        <p class="p-index-subscription__desc-price">¥6,000<span>(税込 ￥6,480)</span></p>
                        <p class="p-index-subscription__desc-info">送料込</p>
                      </div>
                      <button href="#" class="p-index-subscription__desc-button c-button">カートに入れる</button>
                      <p class="p-index-subscription__desc-note">ツイスクが自信を持っておすすめするストアの魅力を存分にお楽しみいただけるプランです。<br>
                        全国の注目ストアから厳選した特別な商品をお届けいたします。<br>
                        迷ったらこちらのプランをおすすめいたします。</p>
                      <p class="p-index-subscription__desc-note">※10〜25日のご指定日に配送</p>
                      <p class="p-index-subscription__desc-note">※送料込</p>

                      <!-- <p class="p-index-subscription__desc-subnote">Twisc ではフードロスを解消すると共にできるだけ作りたてのおやつをお届けするために、2回目以降につきましては、発送日の前週の月曜日00:00に事前決済させていただいております。ご理解いただけますと幸いです。</p> -->
                    </div>
                  </div>
                </div>

              </section>

              <h4></h4>
              <section>

                <div class="p-index-subscription" style="opacity: 1; transform: translate(0px, 0px);">
                  <div class="p-index-subscription-detail">
                    <div class="p-index-subscription__desc left-div" data-v-58e92e3c="">
                      <h3 class="p-index-subscription__desc-name" data-v-58e92e3c="">お試しプラン（年間）</h3>
                      <div class="p-index-subscription__desc-price-container">
                        <p class="p-index-subscription__desc-price">¥34,000<span>(税込 ￥36,720)</span></p>
                        <p class="p-index-subscription__desc-price"><span class="old-price">¥36,000</span><span>(通常料金)</span></p>
                        <p class="p-index-subscription__desc-info">送料込</p>
                      </div>
                      <button href="#" class="p-index-subscription__desc-button c-button">カートに入れる</button>
                      <p class="p-index-subscription__desc-note">まずは試してみたい、そんな方向けにツイスクをお気軽にお試しいただけるプランをご用意いたしました。<br>
                        全国の注目ストアから厳選した商品をお届けいたします。</p>
                      <br>
                      <p class="p-index-subscription__desc-note">※10〜25日の間のご指定日に配送</p>
                      <p class="p-index-subscription__desc-note">※送料込</p>

                      <p class="p-index-subscription__desc-subnote">【年間プランのご注意】<br>
                        ※必ずご一読ください。<br>
                        初回ご注文時に一括でのご請求となります。<br>
                        いつでも停止可能ですが、停止はサービスの停止のみとなり料金は返金されません。<br>
                        スキップ機能もご利用いただけますが、その分につきましても料金は返金されませんのでご注意ください。</p>
                    </div>
                  </div>
                </div>

                <div class="p-index-subscription" style="opacity: 1; transform: translate(0px, 0px);">
                  <div class="p-index-subscription-detail">
                    <div class="p-index-subscription__desc right-div" data-v-58e92e3c="">
                      <h3 class="p-index-subscription__desc-name" data-v-58e92e3c="">スタンダードプラン（年間）</h3>
                      <div class="p-index-subscription__desc-price-container">
                        <p class="p-index-subscription__desc-price">¥67,000<span>(税込 ￥72,360)</span></p>
                        <p class="p-index-subscription__desc-price"><span class="old-price">¥72,000</span><span>(通常料金)</span></p>
                        <p class="p-index-subscription__desc-info">送料込</p>
                      </div>
                      <button href="#" class="p-index-subscription__desc-button c-button">カートに入れる</button>
                      <p class="p-index-subscription__desc-note">ツイスクが自信を持っておすすめするストアの魅力を存分にお楽しみいただけるプランです。<br>
                        全国の注目ストアから厳選した特別な商品をお届けいたします。<br>
                        迷ったらこちらのプランをおすすめいたします。</p>
                      <p class="p-index-subscription__desc-note">※10〜25日のご指定日に配送</p>
                      <p class="p-index-subscription__desc-note">※送料込</p>

                      <p class="p-index-subscription__desc-subnote">【年間プランのご注意】<br>
                        ※必ずご一読ください。<br>
                        初回ご注文時に一括でのご請求となります。<br>
                        いつでも停止可能ですが、停止はサービスの停止のみとなり料金は返金されません。<br>
                        スキップ機能もご利用いただけますが、その分につきましても料金は返金されませんのでご注意ください。</p>
                    </div>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ======= Pricing Section End ======= -->
